import { ToolbarButton } from '@graphiql/react';
import { Fetcher } from '@graphiql/toolkit';
import {
  Client as AuthClient,
  Provider as AuthProvider,
  Status,
  useLogin,
  useLogout,
  useStatus,
} from '@spaceship-fspl/auth';
import { createFetch } from '@spaceship-fspl/fetch';
import { GraphiQL } from 'graphiql';
import { createClient, RequestParams } from 'graphql-sse';
import * as React from 'react';
import { createRoot } from 'react-dom/client';

const preprodUrl = 'https://api.preprod.spaceship.com.au/v0/external';
const defaultAppVersion = '2.37.1';
const defaultPlatform = 'IOS';
const defaultUseCookies = /^(?!.*apple).*$/i.test(navigator.vendor);

/* eslint-disable @typescript-eslint/no-explicit-any */
function getLocalStorage(key: string, defaultVal?: any): any {
  const state = window.localStorage.getItem(key);
  if (state) {
    return JSON.parse(state);
  }
  return defaultVal;
}

function setLocalStorage(key: string, val: any): undefined {
  window.localStorage.setItem(key, JSON.stringify(val));
}

const baseUrl = getLocalStorage('baseUrl', preprodUrl);
const appVersion = getLocalStorage('appVersion', defaultAppVersion);
const platform = getLocalStorage('platform', defaultPlatform);
const useCookies = getLocalStorage('useCookies', defaultUseCookies);

const authClient = new AuthClient({
  storage: {
    async write(state) {
      setLocalStorage('auth', state);
    },
    async read() {
      return getLocalStorage('auth');
    },
  },
  fetch: createFetch({
    url: baseUrl,
    headers: {
      's8-device-id': 'web',
      's8-device-fingerprint': 'graphiql',
      's8-version': appVersion,
      's8-platform': platform,
    },
    credentials: useCookies ? 'include' : undefined,
  }),
  useCookies,
});

const fetcher: Fetcher = async (body) => {
  let token: string | undefined;
  try {
    token = await authClient.ensureToken();
  } catch {
    // Allow unauthenticated requests
  }

  const resp = await fetch(`${baseUrl}/query`, {
    method: 'post',
    credentials: useCookies ? 'include' : undefined,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(token
        ? {
            authorization: `Bearer ${token}`,
          }
        : {}),
      's8-device-fingerprint': 'graphiql',
      's8-version': appVersion,
      's8-platform': platform,
    },
    body: JSON.stringify(body),
  });

  return await resp.json();
};

const sseClient = createClient({
  url: `${baseUrl}/query`,
  retryAttempts: 0,
  headers: async () => {
    let token: string | undefined;
    try {
      token = await authClient.ensureToken();
    } catch {
      // Allow unauthenticated requests
    }

    return {
      ...(token ? { authorization: `Bearer ${token}` } : {}),
      's8-device-fingerprint': 'graphiql',
      's8-version': appVersion,
      's8-platform': platform,
    };
  },
  credentials: useCookies ? 'include' : undefined,
  onMessage: (data) => {
    console.log('GraphQL SSE event: ', data);
  },
});

const sseSplitter: Fetcher = (payload) => {
  // introspection query should not go trough SSE for example
  // so very rudimentary check for now
  if (!payload.query.includes('@defer')) {
    return fetcher(payload);
  }

  let deferred: any = null;
  const pending: Array<any> = [];
  let throwMe: unknown | null = null,
    done = false;
  const dispose = sseClient.subscribe(payload as RequestParams, {
    next: (data) => {
      pending.push(data);
      deferred?.resolve(false);
    },
    error: (err) => {
      throwMe = err;
      deferred?.reject(throwMe);
    },
    complete: () => {
      done = true;
      deferred?.resolve(true);
    },
  });
  return {
    [Symbol.asyncIterator]() {
      return this;
    },
    async next() {
      if (done) return { done: true, value: undefined };
      if (throwMe) throw throwMe;
      if (pending.length) return { value: pending.shift() };
      return (await new Promise(
        (resolve, reject) => (deferred = { resolve, reject }),
      ))
        ? { done: true, value: undefined }
        : { value: pending.shift() };
    },
    async return() {
      dispose();
      return { done: true, value: undefined };
    },
  };
};

function Editor(): JSX.Element {
  const [authLoading, setAuthLoading] = React.useState(false);

  const status = useStatus();
  const login = useLogin();
  const logout = useLogout();

  async function onAuthClick(): Promise<void> {
    let email;
    try {
      setAuthLoading(true);
      if (status === Status.AUTHENTICATED) {
        await logout();
      } else {
        const buia = 'buia-i8mr@test.com';
        const buiapw = 'Qweqwe123';
        email = window.prompt('Email', buia) ?? buia;
        const password = window.prompt('Password', buiapw) ?? buiapw;
        const mfaChallenge = await login(email, password);
        if (mfaChallenge) {
          const code = window.prompt(`MFA Code (${mfaChallenge.type}): `, '');
          if (code && code.length) {
            await login(email, password, undefined, {
              id: mfaChallenge.id,
              token: code,
            });
          }
        }
      }
    } catch (err) {
      window.alert((err as Error).message);
    } finally {
      setLocalStorage('email', email);
      setAuthLoading(false);
    }
  }

  async function onCookiesClick(): Promise<void> {
    setLocalStorage('useCookies', !useCookies);
    location.reload();
  }

  async function onBaseUrlClick(): Promise<void> {
    const newURL = window.prompt('Base URL', preprodUrl) ?? preprodUrl;
    await logout();
    setLocalStorage('baseUrl', newURL);
    location.reload();
  }

  async function onAppVersionClick(): Promise<void> {
    const appVersion = window.prompt('App version', defaultAppVersion);
    setLocalStorage('appVersion', appVersion);
    location.reload();
  }

  async function onPlatformClick(): Promise<void> {
    const platform = window.prompt(
      'Platform (either IOS, ANDROID or WEB)',
      defaultPlatform,
    );
    setLocalStorage('platform', platform);
    location.reload();
  }

  let authCopy = 'Login';
  if (status === Status.AUTHENTICATED) {
    authCopy = 'Logout';
  } else if (authLoading) {
    authCopy = 'Loading...';
  }

  const cookiesCopy = `Cookies: ${useCookies ? 'On' : 'Off'}`;

  return (
    <GraphiQL
      fetcher={sseSplitter}
      toolbar={{
        additionalContent: (
          <>
            <ToolbarButton onClick={onAuthClick} label={authCopy}>
              {status === Status.AUTHENTICATED ? (
                <svg
                  height="1em"
                  viewBox="0 0 24 24"
                  fill="none"
                  strokeWidth={2}
                  stroke="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  className="graphiql-toolbar-icon"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              ) : (
                <svg
                  height="1em"
                  viewBox="0 0 24 24"
                  fill="none"
                  strokeWidth={2}
                  stroke="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  className="graphiql-toolbar-icon"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                  />
                </svg>
              )}
            </ToolbarButton>

            <ToolbarButton onClick={onBaseUrlClick} label="Base URL">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-globe graphiql-toolbar-icon"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="2" y1="12" x2="22" y2="12"></line>
                <path d="M12 2a15.3 15.3 0 0 1 4 10 15.3 15.3 0 0 1-4 10 15.3 15.3 0 0 1-4-10 15.3 15.3 0 0 1 4-10z"></path>
              </svg>
            </ToolbarButton>

            <ToolbarButton onClick={onAppVersionClick} label="App version">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-smartphone graphiql-toolbar-icon"
              >
                <rect x="5" y="2" width="14" height="20" rx="2" ry="2"></rect>
                <line x1="12" y1="18" x2="12.01" y2="18"></line>
              </svg>
            </ToolbarButton>

            <ToolbarButton onClick={onPlatformClick} label="Platform">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-package graphiql-toolbar-icon"
              >
                <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line>
                <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
                <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                <line x1="12" y1="22.08" x2="12" y2="12"></line>
              </svg>
            </ToolbarButton>

            <ToolbarButton onClick={onCookiesClick} label={cookiesCopy}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-package graphiql-toolbar-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 8.25v-1.5m0 1.5c-1.355 0-2.697.056-4.024.166C6.845 8.51 6 9.473 6 10.608v2.513m6-4.871c1.355 0 2.697.056 4.024.166C17.155 8.51 18 9.473 18 10.608v2.513M15 8.25v-1.5m-6 1.5v-1.5m12 9.75-1.5.75a3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0 3.354 3.354 0 0 0-3 0 3.354 3.354 0 0 1-3 0L3 16.5m15-3.379a48.474 48.474 0 0 0-6-.371c-2.032 0-4.034.126-6 .371m12 0c.39.049.777.102 1.163.16 1.07.16 1.837 1.094 1.837 2.175v5.169c0 .621-.504 1.125-1.125 1.125H4.125A1.125 1.125 0 0 1 3 20.625v-5.17c0-1.08.768-2.014 1.837-2.174A47.78 47.78 0 0 1 6 13.12M12.265 3.11a.375.375 0 1 1-.53 0L12 2.845l.265.265Zm-3 0a.375.375 0 1 1-.53 0L9 2.845l.265.265Zm6 0a.375.375 0 1 1-.53 0L15 2.845l.265.265Z"
                />
              </svg>
            </ToolbarButton>
          </>
        ),
      }}
    >
      <GraphiQL.Logo>
        Spaceship GraphQL API ({new URL(baseUrl).hostname})
      </GraphiQL.Logo>
      <GraphiQL.Footer>
        Platform: {platform}, App version {appVersion}
        {status === Status.AUTHENTICATED
          ? `, Logged in as ${getLocalStorage('email')}`
          : 'Unauthenticated'}
      </GraphiQL.Footer>
    </GraphiQL>
  );
}

const container = document.getElementById('root');
if (container) {
  const root = createRoot(container);
  root.render(
    <AuthProvider client={authClient}>
      <Editor />
    </AuthProvider>,
  );
}
